import React from "react";
import { BaseInput } from "./base-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  placeholder?: string;
  onChange: (v: string) => void;
  value?: string;
}

export const BaseSearch: React.FC<IProps> = ({ placeholder='Search...', onChange, value }) => {
  return (
    <div className="bg-[#f2f1f4] flex items-center rounded-lg h-[40px] px-4">
      <FontAwesomeIcon icon={faMagnifyingGlass} className="text-[16px] text-gray-2" />
      <BaseInput value={value} className="!bg-[#f2f1f4] !border-none !shadow-none w-full" placeholder={placeholder} onChange={(e) => onChange(e.target.value)} />
    </div>
  )
}