import { Button, Col, Form, Modal, Row } from "antd";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { BaseInput, PasswordInput } from "src/components/form/base-input";
import { AdminLayout } from "src/components/layout/admin-layout";
import { useAdminUpdatePassword } from "src/hooks/features/useAdminAuth";
import { IUpdatePasswordRequest } from "src/types/auth";
import { useAppSelector } from "src/utils/common";
import { REQUIRED } from "src/utils/rule-form";

export const AdminAcccountSettingPage: React.FC = () => {

  const { data: adminDetailData } = useAppSelector((state) => state.adminDetail);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const { mutate: updatePasswordMutate, isPending } = useAdminUpdatePassword();

  const onChangePassword = (values: IUpdatePasswordRequest) => {
    updatePasswordMutate(values, {
      onSuccess() {
        toast.success('Update password successfully!');
        setIsOpenModal(false);
      },
      onError(err) {
        if(typeof err === 'string') {
          toast.error(err)
        } else {
          toast.error('Update password failed!')
        }
      }
    })
  }

  return (
    <AdminLayout>
      <div>
        <div className='bg-white shadow-primary rounded-xl px-6 py-8 max-sm:rounded-none'>
          <p className='text-[20px] font-semibold text-light-dark'>Account Information</p>
          <Form className="mt-10" layout='vertical' initialValues={adminDetailData ?? {}}>
            <Row gutter={[24, 24]}>
              <Col xl={6} lg={8} md={8} sm={12} xs={24}>
                <Form.Item name='email' label='Email' >
                  <BaseInput className="h-[40px]" disabled />
                </Form.Item>
              </Col>
              <Col xl={6} lg={8} md={8} sm={12} xs={24}>
                <Form.Item name='first_name' label='First Name' >
                  <BaseInput className="h-[40px]" disabled />
                </Form.Item >
              </Col>
              <Col xl={6} lg={8} md={8} sm={12} xs={24}>
                <Form.Item name='last_name' label='Last Name' >
                  <BaseInput className="h-[40px]" disabled />
                </Form.Item>
              </Col>
              <Col xl={6} lg={8} md={8} sm={12} xs={24}>
                <Form.Item name='is_superuser' label='Is Super User'>
                  <BaseInput className="h-[40px]" disabled />
                </Form.Item>
              </Col>

              <Col xl={6} lg={8} md={8} sm={12} xs={24}>
                <Button onClick={() => setIsOpenModal(true)} type='primary' className="h-[40px] w-full bg-main-theme text-white font-bold mt-[1.8rem]">Change Password</Button>
              </Col>
            </Row>
          </Form>
        </div>

        <Modal
          open={isOpenModal}
          destroyOnClose={true}
          footer={null}
          onCancel={() => setIsOpenModal(false)}
          centered
        >
          <div className="font-rale-way">
            <p className="text-[24px] mt-8 font-semibold">
              Update Password
            </p>
            <Form onFinish={onChangePassword} layout='vertical' className="mt-8">
              <Form.Item name='current_password' label='Current Password' rules={[ REQUIRED ]}>
                <PasswordInput className="h-[50px]"/>
              </Form.Item>
              <Form.Item name='new_password' label='New Password' rules={[ REQUIRED ]}>
                <PasswordInput className="h-[50px]" />
              </Form.Item>
              <div className="flex w-full gap-4 mt-8">
                <Button loading={isPending} type='primary' className="bg-main-theme text-[16px] font-bold h-[48px] flex-1" htmlType="submit" >Confirm</Button>
                <Button type='default' className="text-[16px] font-bold h-[48px] flex-1 text-pink-theme-1 border-base-color" onClick={() => setIsOpenModal(false)}>Cancel</Button>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
   </AdminLayout>
  )
};

