import { createSlice } from '@reduxjs/toolkit';
import { IAdminDetailStore } from 'src/types/user';

const initialState: IAdminDetailStore = {
  data: null,
  isLoading: false,
  error: '',
};

const sessionSlice = createSlice({
  name: 'adminDetail',
  initialState: initialState,
  reducers: {
    getAdminDetailLoading: (state) => {
      state.isLoading = true;
    },
    getAdminDetailsSuccess: (state, action?) => {
      state.data = action?.payload;
      state.isLoading = false;
      state.error = '';
    },
    getAdminDetailFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetAdminDetail: (state) => {
      state.data = null;
      state.error = '';
      state.isLoading = false;
    }
  },
});

export const { getAdminDetailFailure, getAdminDetailLoading, getAdminDetailsSuccess, resetAdminDetail } =
  sessionSlice.actions;

export default sessionSlice.reducer;