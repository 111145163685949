import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { Session } from 'src/types/common';
import { paths } from 'src/utils/common';
import { getAdminSession } from 'src/utils/admin-session';

interface Props {
  children: ReactElement;
}

const AdminPublicRoute = ({ children }: Props) => {
  const session: Session = getAdminSession();

  if (session && session.access_token) {
    return <Navigate to={paths.adminMessageManagement} />;
  }

  return children;
};

export default AdminPublicRoute;