import { Divider, Modal } from "antd";
import React from "react";
import MyMessage from "src/components/my-message";
import { OtherMessage } from "src/components/other-message";
import { useAppSelector } from "src/utils/common";
import { IMessage } from "src/types/admin/message-management";
import Scroll from "react-scroll";

const Element = Scroll.Element;

interface IProps {
  open: boolean;
  onCancel: () => void;
}

export const DetailConversationModal: React.FC<IProps> = ({ open, onCancel }) => {
  const { data } = useAppSelector((state) => state.customerConversationDetail);

  return (
    <Modal open={open} width={700} footer={null} onCancel={onCancel} centered>
      <p className='text-[20px] font-semibold text-light-dark'>Message Detail</p>

      <Divider className="mb-0 mt-4" />

      <div className='overflow-y-auto w-[100%] max-xl:w-[100%] max-lg:w-[100%] max-md:w-[100%] max-md:px-4 max-sm:px-2 h-[520px] pr-2'>
        <div className="flex justify-center pt-4">
          <div className="flex flex-col flex-1">
            {data?.messages.map((item: IMessage, index: number) => {
              switch(item.role) {
                case 'user':
                  return (
                    <div key={index}>
                      <OtherMessage message={item.content} time={item.created_at} />
                    </div>
                  );
                case 'remitai':
                  return (
                    <div key={index}>
                      <MyMessage message={item.content} role="remitai" time={item.created_at} />
                    </div>
                  );
                // suppport 
                default: 
                  return (
                    <div key={index}>
                      <MyMessage message={item.content} role='admin' name={item.role} time={item.created_at} />
                    </div>
                  )
              } 
            })}
            <Element name='lastElement' />
          </div>
        </div>
      </div>
    </Modal>
  )
}