import { IConversationListRequest, IConversationListResponse, IResolveConversationResponse, IResolveconversationRequest, ISearchConversationListRequest } from "src/types/admin/message-management";
import { IPaginationParams } from "src/types/common";
import adminRequest from "src/utils/admin-request";

export const getConversationList = (params: IConversationListRequest) =>
  adminRequest.post<IConversationListRequest, IConversationListResponse>(
    '/monitor/conversations',
    params
  );

export const searchConversationList = (payload: ISearchConversationListRequest | null, params: IPaginationParams) =>
  adminRequest.post <ISearchConversationListRequest | null, IConversationListResponse>(
    `/monitor/conversations/search?page=${params?.page}&page_size=${params?.page_size}`,
    payload ?? {}
  );

export const getSupportNeededConversation = (params: IPaginationParams) =>
  adminRequest.post<IConversationListRequest, IConversationListResponse>(
    `/monitor/support_needed_conversations?page=${params?.page}&page_size=${params?.page_size}`,
  );

export const resolveConversation = (body: IResolveconversationRequest) =>
  adminRequest.post<IResolveconversationRequest, IResolveConversationResponse>(
    `/monitor/resolve_conversation`,
    body,
  )