import { getAdminDetailFailure, getAdminDetailLoading, getAdminDetailsSuccess } from './reducers';
import { AppDispatch } from 'src/types/store';
import { API } from 'src/store/api-route';
import adminRequest from 'src/utils/admin-request';

export const getAdminDetail = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(getAdminDetailLoading());
    const res: any = await adminRequest.get(API.adminDetail);
    const data = res.data;
    dispatch(getAdminDetailsSuccess(data));
  } catch (error: any) {
    dispatch(getAdminDetailFailure(error));
  }
};