import React from "react";

interface IProps {
  children: React.ReactElement;
}

export const AdminAuthLayout: React.FC<IProps> = ({ children }) => {
  return (
    <div className="gradient-theme w-full h-[100vh]">
      {
        children
      }
    </div>
  )
}