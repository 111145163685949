import { Select as AntSelect } from 'antd';
import React, { ComponentProps } from 'react';

export const { Option } = AntSelect;

export interface BaseSelectProps extends ComponentProps<typeof AntSelect> {
  width?: any;
  shadow?: any;
  className?: string;
}

export const BaseSelectSmall: React.FC<BaseSelectProps> = ({ className, children, ...props }) => (
  <div className='relative small-rounded'>
    <AntSelect
        style={{
          backgroundColor: props.disabled ? 'rgba(59, 59, 59, 0.3)' : 'transparent',
        }}
        getPopupContainer={(triggerNode) => triggerNode}
        className={className}
        {...props}
        placeholder={''}
      >
        {children}
      </AntSelect>
  </div>
);
