import React from "react";
import { Breadcrum } from "src/components/breadcrum";
import { AdminLayout } from "src/components/layout/admin-layout";
import MyMessage from "src/components/my-message";
import { OtherMessage } from "src/components/other-message";
import Scroll from "react-scroll";
import { useAppSelector } from "src/utils/common";
import { IMessage } from "src/types/admin/message-management";
import { Divider } from "antd";

const Element = Scroll.Element;

export const MessageDetailPage: React.FC = () => {
  const breadcums = [
    {
      title: 'Message Management',
      link: '/admin/message-management'
    },
    {
      title: 'Detail',
      isActive: true,
    }
  ]
  
  const { data } = useAppSelector((state) => state.customerConversationDetail);

  return (
    <AdminLayout>
      <div className='bg-white shadow-primary rounded-xl px-6 py-8 w-[800px]'>
        <Breadcrum breadcrums={breadcums} />
        <p className='text-[20px] font-semibold text-light-dark'>Message Detail</p>

        <Divider className="mb-0 mt-4" />

        <div className='overflow-y-auto w-[100%] max-xl:w-[100%] max-lg:w-[100%] max-md:w-[100%] max-md:px-4 max-sm:px-2 h-[520px] pr-2'>
          <div className="flex justify-center pt-4">
            <div className="flex flex-col flex-1">
              {data?.messages.map((item: IMessage, index: number) => {
                switch(item.role) {
                  case 'remitai':
                    return (
                      <div key={index}>
                        <OtherMessage message={item.content} time={item.created_at} />
                      </div>
                    );
                  case 'user':
                    return (
                      <div key={index}>
                        <MyMessage message={item.content} />
                      </div>
                    );
                  default: 
                    return null;
                } 
              })}
              <Element name='lastElement' />
            </div>
          </div>
        </div>
      </div>

   </AdminLayout>
  )
}