import { getAdminAuthSuccess, getAdminAuthFailure, getAdminAuthLoading } from './reducers';
import { AppDispatch } from 'src/types/store';
import { paths } from 'src/utils/common';
import adminRequest from 'src/utils/admin-request';
import { API } from 'src/store/api-route';
import { IAdminLoginForm } from 'src/types/auth';
import { setAdminSession } from 'src/utils/admin-session';
import { getAdminDetailsSuccess } from 'src/store/admin/admin-detail/reducers';

export const getAdminToken = (navigate: any, body: IAdminLoginForm) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getAdminAuthLoading());
    const adminAuth: any = await adminRequest.post(API.adminLogin, body);
    const token = adminAuth?.data.data;

    const adminDetail: any = await adminRequest.get(API.adminDetail, {}, {
      'x-access-token': token?.access_token
    });

    setAdminSession(token);
    dispatch(getAdminAuthSuccess(token));
    dispatch(getAdminDetailsSuccess({ data: adminDetail.data }));
    navigate(paths.adminMessageManagement);

  } catch (error: any) {
    if (typeof(error) === 'string') {
      dispatch(getAdminAuthFailure(error));
    } else {
      dispatch(getAdminAuthFailure('Can not login'));
    }
  }
};