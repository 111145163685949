import axios from "axios";
import { StorageKey, StorageUtils } from "./local-storage";
import { getAdminSession, setAdminSession } from "./admin-session";
import { paths } from "./common";

const session = getAdminSession();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let access_token = session?.access_token;
let refreshing_token: any = null;

const AxiosClient = axios.create({
  baseURL: process.env.REACT_APP_BACK_END_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const getRefreshToken = () => {
  const session = getAdminSession();
  return axios.post(
    process.env.REACT_APP_BACK_END_BASE_URL + `refresh`,
    {
      refresh_token: session?.refresh_token,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

const publicUrl = ['/admin_token'];


AxiosClient.interceptors.request.use((req) => {
  const session = StorageUtils.getObject(StorageKey.ADMIN_SESSION);
  if (req.url && session?.access_token && req.headers && !publicUrl.includes(req.url)) {
    req.headers['x-access-token'] = session?.access_token;
  }
  return req;
});

AxiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: any) => {
    const config = error.config;
    if (error.response && error.response.status === 401 && !config._retry && !publicUrl.includes(config.url)) {
      config._retry = true;
      try {
        refreshing_token = refreshing_token ? refreshing_token : getRefreshToken();
        const res = await refreshing_token;
        refreshing_token = null;
        if (res.data.data.access_token && res.data.data.refresh_token) {
          setAdminSession({
            access_token: res.data.data.access_token,
            refresh_token: res.data.data.refresh_token,
          });
          access_token = res.data.access_token;
        }
        return AxiosClient(config);
      } catch (err) {
        StorageUtils.remove(StorageKey.ADMIN_SESSION);
        window.location.replace(paths.adminLogin);
        return Promise.reject(err);
      }
    } else if (error?.response?.data?.detail) {
      throw error?.response?.data?.detail;
    } else if (error?.response?.data) {
      throw error?.response?.data
    } else {
      throw error.message;
    }
  },
);

const get = <ReqType, ResType>(
  url: string,
  params?: ReqType,
  customHeaders?: any,
): Promise<ResType> => {
  return AxiosClient.get(url, { params, headers: customHeaders });
};

function post<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any,
): Promise<ResType> {
  return AxiosClient.post(url, data, { headers: customHeaders });
}


async function put<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any,
): Promise<ResType> {
  return AxiosClient.put(url, data, { headers: customHeaders });
}

async function _delete<ReqType, ResType>(url: string, params?: ReqType): Promise<ResType> {
  return AxiosClient.delete(url, { params });
}

const adminRequest = { get, post, put, _delete };
export default adminRequest;