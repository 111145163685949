import { IMessageHistoryBody, IMessageHistoryRequest, IMessageHistoryResponse, ISendWhatsappMessageRequest, ISendWhatsappMessageResponse } from "src/types/admin/inbox";
import adminRequest from "src/utils/admin-request";


export const getMessageHistory = (request?: IMessageHistoryRequest) =>
  adminRequest.post<IMessageHistoryBody, {data: IMessageHistoryResponse}>(
    `/admins/message_history?page_size=${request?.params.page_size}&page=${request?.params?.page}&offset=${request?.params?.offset}`,
    request?.body,
  );

export const sendWhatsappMessage = (body: ISendWhatsappMessageRequest) =>
  adminRequest.post<ISendWhatsappMessageRequest, ISendWhatsappMessageResponse>(
    '/admins/whatsapp_message',
    body
  );
