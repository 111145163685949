import React from "react";
import Logo from "src/components/layout/logo";
import { faQrcode, faRightFromBracket, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Skeleton } from "antd";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { deleteAdminSession } from "src/utils/admin-session";
import { useAppSelector } from "src/utils/common";

const MENU = [
  {
    title: 'Message Management',
    icon: <FontAwesomeIcon icon={faQrcode} />,
    link: '/admin/message-management',
    isOnlySuperUser: false,
  },
  {
    title: 'Admin Management',
    icon: <FontAwesomeIcon icon={faUserTie} />,
    link: '/admin/admin-management',
    isOnlySuperUser: true,
  },
  {
    title: 'Inbox',
    icon: <FontAwesomeIcon icon={faCommentDots} />,
    link: '/admin/inbox',
    isOnlySuperUser: false,
  }
]

export const AdminSideBar = () => {
  const { pathname } = useLocation();
  const { data: adminDetail, isLoading } = useAppSelector((state) => state.adminDetail);

  const onLogout = () => {
    deleteAdminSession();
    window.location.reload();
  }

  return (
    <>
      <div className="relative h-full">
        <div className="h-[48px] text-center pt-2">
          <div className="block max-xl:block max-lg:hidden max-sm:block">
            <Logo /> 
          </div>
        </div>
        {
          isLoading ? (
            <div className="mt-12 px-4 max-xl:px-4 max-lg:px-2">
              <Skeleton.Button active={true} block={true} size='large' />
              <Skeleton.Button active={true} block={true} size="large" className="mt-1" />
              <Skeleton.Button active={true} block={true} size="large" className="mt-1" />
            </div>
          ) : (
            <div className="mt-12 px-4 max-xl:px-4 max-lg:px-2">
              {
                MENU.map((item, index) => (
                  <Link key={index} to={item.link} className={`${!adminDetail?.is_superuser && item.isOnlySuperUser ? 'hidden' : ''} ${ pathname?.includes(item.link) ? 'bg-main-theme' : 'bg-transparent' } flex gap-2 items-center py-2 px-3 rounded-lg mb-2`}>
                    <div className={` ${ pathname?.includes(item.link) ? 'text-white' : 'text-secondary' } text-xl`}>
                      {item.icon}
                    </div>
                    <div className={` ${ pathname?.includes(item.link) ? 'text-white' : 'text-secondary' } font-medium block max-xl:block max-lg:hidden max-sm:block`}>
                      {item.title}
                    </div>
                  </Link>
                ))
              }
            </div>
          )
        }
        
        <div className="absolute bottom-16 w-full">
          <Divider className="mb-2" />
          <div className="px-4 w-full">
            <div className="w-full text-gray-1 h-[44px] text-[16px] font-bold flex items-center px-2 cursor-pointer" onClick={onLogout}>
              <FontAwesomeIcon icon={faRightFromBracket} className="text-[16px]" />&nbsp;
                <div className="block max-xl:block max-lg:hidden max-sm:block">Logout</div>
              </div>
          </div>
        </div>
      </div>
    </>
  )
}