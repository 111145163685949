import React from "react";
import { IAdminDeleteResponse, IAdminListResponse } from "src/types/admin/admin-management";
import { IAdminUpdateForm } from "src/types/auth";
import { IPaginationParams } from "src/types/common";
import adminRequest from "src/utils/admin-request";

export const getAdminList = (params: IPaginationParams) =>
  adminRequest.get<IPaginationParams, IAdminListResponse>(
    '/admins',
    params
  );

export const createAdmin = (body: IAdminUpdateForm) =>
  adminRequest.post<IAdminUpdateForm, IAdminListResponse>(
    '/admins',
    body
  );

export const deleteAdmin = (id: string | number) => 
  adminRequest._delete<unknown, IAdminDeleteResponse>(
    `/admins/${id}`,
  )

export const updateAdmin = (data: { body: IAdminUpdateForm, id: React.Key },) =>
  adminRequest.put<IAdminUpdateForm, IAdminListResponse>(
    `/admins/${data?.id}`,
    data?.body,
  );