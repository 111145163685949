import { createAdmin, deleteAdmin, getAdminList, updateAdmin } from "src/api/adminMangagement";
import useAppQuery from "src/hooks/useAppQuery";
import useAppMutation, { AppMutationOptions } from "../useAppMutation";
import { IPaginationParams } from "src/types/common";

export const useGetAdminListQuery = (params: IPaginationParams) =>
  useAppQuery({
    queryKey: ['admin-list', params],
    queryFn: () => getAdminList(params),
  })

export const useCreateAdminMutation = (options?: AppMutationOptions) =>
  useAppMutation(createAdmin, {
    useAppMutationProps: options,
  })

export const useDeleteAdminMutation = (options?: AppMutationOptions) =>
  useAppMutation(deleteAdmin, {
    useAppMutationProps: options,
  })

export const useUpdateAdminMutation = (options?: AppMutationOptions) =>
  useAppMutation(updateAdmin, {
    useAppMutationProps: options,
  })