import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export const NotFoundPage = () => {
  return (
    <div className="h-[100vh] w-[100vw] font-rale-way flex flex-col justify-center items-center">
      <div className="text-[120px] font-bold text-pink-theme-1">Oops!</div>
      <div className="mt-4 font-semibold">404 - Page Not found</div>
      <div className="max-w-[420px] text-center mt-2 px-2">The page you are looking for might have been removed had its name changed or is temporarily unavailable.</div>
    
      <div>
        <Link to='/'>
          <Button className="mt-4 bg-main-theme font-bold h-[50px] px-10" type="primary">GO TO HOME PAGE</Button>
        </Link>
      </div>
    </div>
  )
}
