import { createSlice } from '@reduxjs/toolkit';
import { ICustomerConversationDetail } from 'src/types/admin/message-management';

const initialState: ICustomerConversationDetail = {
  data: null,
  isLoading: false,
};

const sessionSlice = createSlice({
  name: 'adminCustomerConversationDetail',
  initialState: initialState,
  reducers: {
    getCustomerConversationDetailLoading: (state) => {
      state.isLoading = true;
    },
    getCustomerConversationDetailSuccess: (state, action?) => {
      state.data = action?.payload;
      state.isLoading = false;
    },
    getCustomerConversationDetailFailure: (state, action) => {
      state.isLoading = false;
    },
    resetCustomerConversationDetail: (state) => {
      state.data = null;
      state.isLoading = false;
    }
  },
});

export const { getCustomerConversationDetailLoading, getCustomerConversationDetailSuccess, getCustomerConversationDetailFailure, resetCustomerConversationDetail } =
  sessionSlice.actions;

export default sessionSlice.reducer;