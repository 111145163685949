import { Button, Col, Form, Row } from "antd";
import React from "react";
import { toast } from "react-toastify";
import { BaseInput } from "src/components/form/base-input";
import { BaseSelectSmall } from "src/components/form/base-select-small";
import { useUpdateAdminMutation } from "src/hooks/features/useAdminManagement";
import { IAdmin } from "src/types/admin/admin-management";
import { IAdminUpdateForm } from "src/types/auth";
import { useQueryClient } from "src/utils/react-query";
import { CHECK_EMAIL, REQUIRED } from "src/utils/rule-form";

interface IProps {
  initialValues: IAdmin;
  onSuccess: () => void;
}

export const AdminForm: React.FC<IProps> = ({ initialValues, onSuccess }) => {

  const { mutate: updateAdminMutate, isPending } = useUpdateAdminMutation();
  const queryClient = useQueryClient();
  
  const onUpdateAdmin = (v: IAdmin) => {
    const payload: IAdminUpdateForm = {
      is_active: v.is_active,
      first_name: v.first_name,
      last_name: v.last_name,
      is_superuser: v.is_superuser,
    }
    updateAdminMutate({
      body: payload,
      id: initialValues?.id,
    }, {
      onSuccess() {
        toast.success('Update successfully!');
        queryClient.invalidateQueries({ queryKey: ['admin-list'] });
        onSuccess();
      },
      onError(err: any) {
        if (typeof err === 'string') {
          toast.error(err);
        } else {
          toast.error('Can not update!')
        }
      }
    })
  }

  return (
    <Form initialValues={initialValues} layout='vertical' onFinish={(v) => onUpdateAdmin(v)}>
      <Row gutter={[24, 24]}>
        <Col span={6}>
          <Form.Item name='first_name' label='First Name' rules={[ REQUIRED ]}>
          <BaseInput className="h-[40px] rounded-lg" />
        </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='last_name' label='Last Name' rules={[ REQUIRED ]}>
            <BaseInput className="h-[40px] rounded-lg" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='is_active' label='Status' rules={[ REQUIRED ]}>
            <BaseSelectSmall
              className="h-[40px] !rounded-lg"
              options={[
                {
                  label: 'Active',
                  value: true,
                },
                {
                  label: 'Inactive',
                  value: false,
                }
              ]} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name='is_superuser' label='Super User' rules={[ REQUIRED ]}>
            <BaseSelectSmall
              className="h-[40px] !rounded-lg"
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                }
              ]} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <div className="flex justify-center">
          <Button loading={isPending} htmlType="submit" className="font-rale-way h-[40px] bg-main-theme w-[142px] font-bold text-[14px]" type="primary">Update</Button>
        </div>
      </Form.Item>
    </Form>
  )
}