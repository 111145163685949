import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { useCreateAdminMutation, useDeleteAdminMutation, useGetAdminListQuery } from 'src/hooks/features/useAdminManagement';
import { IAdmin } from 'src/types/admin/admin-management';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useQueryClient } from 'src/utils/react-query';
import { AdminForm } from './admin-form';
import { IAdminUpdateForm } from 'src/types/auth';
import { IPaginationParams } from 'src/types/common';

interface IRecord extends IAdmin {
  key: React.Key,
}

const inittialParams: IPaginationParams = {
  page: 1,
  page_size: 10,
}

export const useAdminManagement = () => {
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<null | number>(null);
  const [isOpenCreateAdmin, setIsOpenCreateAdmin] = useState<boolean>(false);

  const [editingKey, setEditingKey] = useState<React.Key>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const queryClient = useQueryClient();
  const [params, setParams] = useState<IPaginationParams>(inittialParams);

  const { mutate: createAdminMutate, isPending: createAdminPending } = useCreateAdminMutation();
  const { mutate: deleteAdminMutate } = useDeleteAdminMutation();
  const { data: adminListData, isLoading: adminListLoading } = useGetAdminListQuery(params);
  const total = adminListData?.data?.metadata?.total_conversations ?? 0;

  const handleMenuClick = (record: IRecord) => {
    const isExpanded = expandedRowKeys.includes(record.key);
    const newExpandedRowKeys = isExpanded
      ? expandedRowKeys.filter((key) => key !== record.key)
      : [record.key];

    setExpandedRowKeys(newExpandedRowKeys);
    setEditingKey(isExpanded ? '' : record.key);
  };

  const columns: ColumnsType<IRecord> = [
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      render: (v: string) => (
        <div>{v}</div>
      )
    },
    {
      title: 'First Name',
      key: 'first_name',
      dataIndex: 'first_name',
    },
    {
      title: 'Last Name',
      key: 'last_name',
      dataIndex: 'last_name',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render:(v, record) => {
        if(record?.is_active) {
          return (
            <Button type='primary' className='!bg-green-400 !text-white font-semibold shadow-none'>Active</Button>
          )
        } else {
          return (
            <Button className='!bg-orange-400 font-semibold shadow-none' type='primary'>Inactive</Button>
          )
        }
      }
    },
    {
      title: 'User',
      key: 'User',
      dataIndex: 'user',
      render:(v, record) => {
        return <Button type='primary' className='!bg-[#6d32a8] !text-white font-semibold shadow-none'>{record?.is_superuser ? 'Super Admin' : 'Admin'}</Button>
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render:(v, record) => (
        <Button onClick={() => setIsOpenConfirmDelete(record?.id)} type='primary' className='bg-main-theme font-semibold shadow-none'><FontAwesomeIcon icon={faTrashCan}/>&nbsp; Delete</Button>
      )
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_, record: IRecord) => (
        <Button
          onClick={() => handleMenuClick(record)}
          type="default"
          className='!text-pink-theme-1 border-pink-theme-1'
          icon={
            <div>
              {expandedRowKeys.includes(record?.key) ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
            </div>
          }
        />
      ),
    },
  ]

  const dataSource = useMemo(() => {
    return adminListData?.data?.data?.values?.map((item) => ({
      ...item,
      key: item.id,
    }))
  }, [adminListData]);

  useEffect(() => {
    if (adminListData?.data?.data?.values && adminListData?.data?.data?.values?.length === 0) {
      setParams((prev) => ({ ...prev, page: (prev?.page && prev?.page > 1) ? prev?.page - 1 : 0 }))
    }
  }, [adminListData])

  const buttons = [
    {
      icon: <FontAwesomeIcon icon={faPlus} className='font-semibold text-[16px]' />,
      title: 'Create',
      disabled: false,
      onClick: () => setIsOpenCreateAdmin(true),
    }
  ]

  const onCreateAdmin = (v: IAdminUpdateForm) => {
    createAdminMutate(v, {
      onSuccess() {
        queryClient.invalidateQueries({queryKey: ['admin-list']});
        setIsOpenCreateAdmin(false);
      },
      onError(error: any) {
        if (typeof error === 'string') {
          toast.error(error);
        }
      },
    })
  }

  const onConfirmDelete = () => {
    isOpenConfirmDelete !== null &&
    deleteAdminMutate(isOpenConfirmDelete, {
      onSuccess() {
        setIsOpenConfirmDelete(null);
        queryClient.invalidateQueries({queryKey: ['admin-list']});
        toast.success('Delete admin successfully!');
      },
      onError(err: any) {
        if (typeof err === 'string') {
          toast.error(err);
        }
        setIsOpenConfirmDelete(null)
      }
    })
  }

  const expandedRowRender = (record: IRecord) => {
    if (editingKey === record.key) {
      return <AdminForm initialValues={record} onSuccess={() => handleMenuClick(record)} />;
    }
    return null;
  };

  const onChangePage = (v: number) => {
    setParams((prev) => ({ ...prev, page: v }));
  }

  return {
    columns,
    isOpenConfirmDelete,
    buttons,
    dataSource,
    adminListLoading,
    isOpenCreateAdmin,
    createAdminPending,
    editingKey,
    expandedRowKeys,
    currentPage: params.page || 1,
    total,
    pageSize: inittialParams.page_size,
    onChangePage,
    setExpandedRowKeys,
    expandedRowRender,
    setEditingKey,
    setIsOpenCreateAdmin,
    setIsOpenConfirmDelete,
    onCreateAdmin,
    onConfirmDelete,
  };
}