import { Button, Col, Form, Modal, Row, Table } from 'antd';
import React from 'react';
import { AdminLayout } from 'src/components/layout/admin-layout';
import { useAdminManagement } from './index.utils';
import { TableAction } from 'src/components/table-action';
import { ModalConfirm } from 'src/components/modal/modal-confirm';
import FormItem from 'antd/es/form/FormItem';
import { BaseInput, PasswordInput } from 'src/components/form/base-input';
import { CHECK_EMAIL, REQUIRED } from 'src/utils/rule-form';
import { BaseSelectSmall } from 'src/components/form/base-select-small';
import { BasePagination } from 'src/components/form/pagination';

export const AdminManagement: React.FC = () => {
  const { 
    columns,
    isOpenConfirmDelete,
    buttons,
    dataSource,
    adminListLoading,
    isOpenCreateAdmin,
    expandedRowKeys,
    createAdminPending,
    currentPage,
    total,
    pageSize,
    onChangePage,
    setExpandedRowKeys,
    expandedRowRender,
    setEditingKey,
    setIsOpenCreateAdmin,
    setIsOpenConfirmDelete,
    onCreateAdmin,
    onConfirmDelete,
  } = useAdminManagement()

  const expandableProps = expandedRowRender
  ? {
      expandable: {
        expandedRowRender: expandedRowRender,
        expandedRowKeys: expandedRowKeys,
        onExpand: (expanded: boolean, record: any) => {
          if (expanded) {
            setExpandedRowKeys && setExpandedRowKeys([record.key]);
            setEditingKey && setEditingKey(record.key);
          } else {
            setExpandedRowKeys && setExpandedRowKeys([]);
            setEditingKey && setEditingKey('');
          }
        },
        showExpandColumn: false,
      },
    }
  : {};

  return (
   <AdminLayout>
    <div className='bg-white shadow-primary rounded-xl px-6 py-8 max-sm:rounded-none'>
      <p className='text-[20px] font-semibold text-light-dark'>Admin Management</p>
      <div className='mt-8'>
        <TableAction buttons={buttons} showSearch={false} />

        <Table
          loading={adminListLoading}
          columns={columns}
          dataSource={dataSource} 
          pagination={false}
          scroll={{ x: 'max-content' }}
          {...expandableProps}
        />

      <BasePagination
        current={currentPage}
        pageSize={pageSize}
        total={total}
        onChange={(page) => onChangePage(page ?? 1)}
      />
      </div>

      <ModalConfirm
        isOpen={isOpenConfirmDelete !== null}
        onCancel={() => setIsOpenConfirmDelete(null)}
        title='Are you sure to delete this record!'
        onConfirm={() => onConfirmDelete()}
      />

      <Modal
        title='Create Admin'
        footer={null}
        open={isOpenCreateAdmin}
        centered
        onCancel={() => setIsOpenCreateAdmin(false)}
        destroyOnClose
      >
        <Form
          layout='vertical'
          className="font-rale-way"
          onFinish={onCreateAdmin}
          initialValues={{
            is_active: false,
            is_superuser: false,
          }}
        >
          <FormItem name='email' label='Email' rules={[ REQUIRED, CHECK_EMAIL ]}>
            <BaseInput className="h-[50px] rounded-lg" />
          </FormItem>
          <FormItem name='first_name' label='First Name' rules={[ REQUIRED ]}>
            <BaseInput className="h-[50px] rounded-lg" />
          </FormItem>
          <FormItem name='last_name' label='Last Name' rules={[ REQUIRED ]}>
            <BaseInput className="h-[50px] rounded-lg" />
          </FormItem>
          <FormItem className="relative" name='password' label='Password' rules={[ REQUIRED ]}>
            <PasswordInput className="h-[50px] rounded-lg" />
          </FormItem>
          <Row gutter={[12,12]}>
            <Col span={12}>
              <FormItem className="relative" name='is_active' label='Status' rules={[ REQUIRED ]}>
                <BaseSelectSmall className="h-[50px] rounded-lg" options={[
                  {
                    value: true,
                    label: 'Active'
                  },
                  {
                    value: false,
                    label: 'Inactive'
                  }
                ]} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem className="relative" name='is_superuser' label='Super User' rules={[ REQUIRED ]}>
                <BaseSelectSmall className="h-[50px] rounded-lg" options={[
                  {
                    value: true,
                    label: 'Yes'
                  },
                  {
                    value: false,
                    label: 'No'
                  }
                ]} />
              </FormItem>
            </Col>
          </Row>
          <FormItem>
            <div className="flex justify-center">
              <Button loading={createAdminPending} htmlType="submit" className="font-rale-way h-[50px] bg-main-theme w-[142px] font-bold text-[14px]" type="primary">Create</Button>
            </div>
          </FormItem>
        </Form>
      </Modal>
    </div>
   </AdminLayout>
  );
};
