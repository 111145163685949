import { Col, Form, Row } from "antd";
import React from "react";
import { BaseInput } from "src/components/form/base-input";
import { IConversation } from "src/types/admin/message-management";

interface IProps {
  initialValues: IConversation;
}

export const ConversationForm: React.FC<IProps> = ({ initialValues }) => {

  return (
    <Form initialValues={initialValues} layout='vertical'>
      <Row gutter={[24, 24]}>
        <Col span={6}>
          <Form.Item name='session' label='Session'>
            <BaseInput className="h-[40px] rounded-lg" disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='user_name' label='User Name'>
          <BaseInput className="h-[40px] rounded-lg" disabled />
        </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label='Message'>
          <BaseInput className="h-[40px] rounded-lg" value={initialValues?.messages?.length && initialValues?.messages[0]?.content} disabled />
        </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}