import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

interface IProps {
  breadcrums: { link?: string, title: string, isActive?: boolean }[]
}

export const Breadcrum: React.FC<IProps> = ({ breadcrums }) => {
  return (
    <div className="mb-4">
      {breadcrums.map((item, index) => (
        <span className="text-[14px] text-gray-2 font-medium">
          {
            item.link ? (<Link to={item?.link} className="mr-2 hover:underline">{item?.title}</Link>) : (<span className="mr-2 text-pink-theme-1  ">{item?.title}</span>)
          }
          {
            !!(index + 1 !== breadcrums?.length) && (
              <span className="mr-2">
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            )
          }
        </span>
      ))}
    </div>
  )
}