import { Button, Form, Modal } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BaseInput } from "src/components/form/base-input";
import { AdminAuthLayout } from "src/components/layout/admin-auth-layout";
import Logo from "src/components/layout/logo";
import { useAdminForgotPassword } from "src/hooks/features/useAdminAuth";
import { IForgotPasswordRequest } from "src/types/auth";
import { paths } from "src/utils/common";
import { REQUIRED } from "src/utils/rule-form";

export const AdminForgotPasswordPage: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const { mutate, isPending } = useAdminForgotPassword();

  const onSubmit = (values: IForgotPasswordRequest) => {
    mutate(values, {
      onSuccess: (response) => {
        setIsSuccess(true);
      }
    });
  }

  return (
    <AdminAuthLayout>
      <>
        <Modal
            open={!isSuccess}
            closeIcon={false}
            footer={null}
            centered
            width={500}
            styles={{
              mask: {
                backgroundColor: 'transparent',
              },
              content: {
                padding: '8px 8px',
              }
            }}
          >
            <div className="bg-white max-w-[460px] px-8 pb-3 pt-5 rounded-3xl">
            <div className="text-center">
              <Logo fontSize='text-[18px]' />
            </div>
            <div className="text-center text-[32px] font-bold mt-4 mb-8">Forgot Password</div>

            <Form layout='vertical' className="font-rale-way" onFinish={onSubmit}>
              <FormItem name='user_email' label='Email' rules={[ REQUIRED ]}>
                <BaseInput className="h-[50px] rounded-lg" />
              </FormItem>

              <div>Back to <Link to={paths.adminLogin} className="hover:underline font-medium cursor-pointer !text-pink-theme-1">Login!</Link></div>

              <FormItem className="mt-4">
                <div className="flex justify-center">
                  <Button htmlType="submit" loading={isPending} className="font-rale-way h-[50px] bg-main-theme w-[180px] font-semibold text-[16px] mt-2" type="primary">Send me an email</Button>
                </div>
              </FormItem>
            </Form>
          </div>
        </Modal>

        <Modal
          open={isSuccess}
          closeIcon={false}
          footer={null}
          centered
          width={500}
          styles={{
            mask: {
              backgroundColor: 'transparent',
            },
            content: {
              padding: '8px 8px',
            }
          }}
        >
          <div className="bg-white w-full px-8 pb-3 pt-5 rounded-3xl font-rale-way">
            <div className="text-center">
              <Logo fontSize='text-[18px]' />
            </div>
            <div className="text-center text-[18px] font-medium mt-4 mb-8 text-gray-1">We've sent you an email with guide to reset your password. Check it out!</div>

            <div className="flex justify-center">
              <Link to={paths.adminLogin}>
                <Button className="font-rale-way h-[50px] bg-main-theme w-[160px] font-semibold text-[16px] mb-3" type="primary">Go to Login</Button>
              </Link>
            </div>
          </div>
        </Modal>
      </>
    </AdminAuthLayout>
  )
}