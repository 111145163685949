import { adminForgotPassword, adminLogin, adminSignup, adminUpdatePassword } from 'src/api/adminAuth';
import useAppMutation, { AppMutationOptions } from '../useAppMutation';

export const useAdminLoginMutation = (options?: AppMutationOptions) =>
  useAppMutation(adminLogin, {
    useAppMutationProps: options,
  });

export const useAdminSignupMutation = (options?: AppMutationOptions) =>
  useAppMutation(adminSignup, {
    useAppMutationProps: options,
  });

export const useAdminForgotPassword = (options?: AppMutationOptions) =>
  useAppMutation(adminForgotPassword, {
    useAppMutationProps: options,
  });

export const useAdminUpdatePassword = (options?: AppMutationOptions) =>
  useAppMutation(adminUpdatePassword, {
    useAppMutationProps: options,
  });