import { StorageKey, StorageUtils } from './local-storage';
import { Session } from 'src/types/common';

// set session to local
export const setAdminSession = (session: Session | null) => {
  StorageUtils.saveObject(StorageKey.ADMIN_SESSION, session);
};

// get
export const getAdminSession = () => {
  const session = StorageUtils.getObject(StorageKey.ADMIN_SESSION);
  if (session) {
    return session;
  } else {
    localStorage.removeItem(StorageKey.ADMIN_SESSION);
  }

  setAdminSession(null);
  localStorage.removeItem(StorageKey.ADMIN_SESSION);
  return null;
};

export const deleteAdminSession = () => {
  StorageUtils.saveObject(StorageKey.ADMIN_SESSION, {});
};
