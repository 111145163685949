import { IAdminLoginForm, IAdminSignupRequest, IForgotPasswordRequest, IForgotPasswordResonse, IUpdatePasswordRequest, IUpdatePasswordResponse } from "src/types/auth";
import adminRequest from "src/utils/admin-request";

export const adminLogin = (body: IAdminLoginForm) =>
  adminRequest.post<IAdminLoginForm, any>('/admin_token', body);

export const adminSignup = (body: IAdminSignupRequest) => 
  adminRequest.post<IAdminSignupRequest, any>('/admins/signup', body);

export const adminForgotPassword = (body: IForgotPasswordRequest) =>
  adminRequest.post<IForgotPasswordRequest, IForgotPasswordResonse>(`/admins/forgot-password`, body);

export const adminUpdatePassword = (body: IUpdatePasswordRequest) =>
  adminRequest.post<IUpdatePasswordRequest, IUpdatePasswordResponse>('/admins/update-password', body);