export interface IRouteObject {
  path: string;
  isPublic?: boolean;
  component: any;
  authorize?: AUTHORIZE;
}

export enum AUTHORIZE {
  GUEST = 'GUEST',
  ADMIN = 'ADMIN',
}
