import { Button, Form, Modal } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseInput, PasswordInput } from "src/components/form/base-input";
import { AdminAuthLayout } from "src/components/layout/admin-auth-layout";
import Logo from "src/components/layout/logo";
import { useAdminSignupMutation } from "src/hooks/features/useAdminAuth";
import { IAdminSignupForm, IAdminSignupRequest } from "src/types/auth";
import { paths } from "src/utils/common";
import { CHECK_EMAIL, REQUIRED } from "src/utils/rule-form";

export const AdminSignupPage: React.FC = () => {
  const { mutate: adminSignupMutate, isPending } = useAdminSignupMutation();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const onSignup = (values: IAdminSignupForm) => {
    const payload: IAdminSignupRequest = {
      email: values?.email,
      first_name: values?.first_name,
      last_name: values?.last_name,
      password: values?.password,
    }

    adminSignupMutate(payload, {
      onSuccess() {
        setIsSuccess(true);
      },
      onError(err: any) {
        if (typeof err === 'string') {
          toast.error(err)
        } else {
          toast.error('Could not signup!')
        }
      },
    });
  }

  return (
    <AdminAuthLayout>
      <div>
        <Modal
          open={!isSuccess}
          closeIcon={false}
          footer={null}
          centered
          width={500}
          styles={{
            mask: {
              backgroundColor: 'transparent',
            },
            content: {
              padding: '8px 8px',
            }
          }}
        >
          <div className="bg-white w-full px-8 pb-3 pt-5 rounded-3xl font-rale-way">
            <div className="text-center">
              <Logo fontSize='text-[18px]' />
            </div>
            <div className="text-center text-[32px] font-bold mt-4 mb-8">Admin Signup</div>

            <Form layout='vertical' className="font-rale-way" onFinish={onSignup}>
              <FormItem name='email' label='Email' rules={[ REQUIRED, CHECK_EMAIL ]}>
                <BaseInput className="h-[50px] rounded-lg" />
              </FormItem>
              <FormItem name='first_name' label='First Name' rules={[ REQUIRED ]}>
                <BaseInput className="h-[50px] rounded-lg" />
              </FormItem>
              <FormItem name='last_name' label='Last Name' rules={[ REQUIRED ]}>
                <BaseInput className="h-[50px] rounded-lg" />
              </FormItem>
              <FormItem className="relative" name='password' label='Password' rules={[ REQUIRED ]}>
                <PasswordInput className="h-[50px] rounded-lg" />
              </FormItem>
              <FormItem className="relative" name='confirm_password' label='Confirm Password' rules={[ REQUIRED ]}>
                <PasswordInput className="h-[50px] rounded-lg" />
              </FormItem>
              <div>Already have an account, <Link to={paths.adminLogin} className="hover:underline font-medium cursor-pointer !text-pink-theme-1">Login here!</Link></div>
              <FormItem>
                <div className="flex justify-center">
                  <Button loading={isPending} htmlType="submit" className="font-rale-way h-[50px] bg-main-theme w-[160px] font-semibold text-[16px] mt-6" type="primary">Signup</Button>
                </div>
              </FormItem>
            </Form>
            
          </div>
        </Modal>

        <Modal
          open={isSuccess}
          closeIcon={false}
          footer={null}
          centered
          width={500}
          styles={{
            mask: {
              backgroundColor: 'transparent',
            },
            content: {
              padding: '8px 8px',
            }
          }}
        >
          <div className="bg-white w-full px-8 pb-3 pt-5 rounded-3xl font-rale-way">
            <div className="text-center">
              <Logo fontSize='text-[18px]' />
            </div>
            <div className="text-center text-[18px] font-medium mt-4 mb-8 text-gray-1">Your submit was registed successfully. Please wait for Remit team's approval!</div>

            <div className="flex justify-center">
              <Link to={paths?.adminLogin}>
                <Button className="font-rale-way h-[50px] bg-main-theme w-[160px] font-semibold text-[16px] mb-3" type="primary">Go to Login</Button>
              </Link>
            </div>
          </div>
        </Modal>
      </div>
    </AdminAuthLayout>
  )
}