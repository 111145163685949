import { IMessageHistoryRequest } from "src/types/admin/inbox";
import useAppQuery from "../useAppQuery";
import { getMessageHistory, sendWhatsappMessage } from "src/api/adminInbox";
import useAppMutation, { AppMutationOptions } from "../useAppMutation";

export const useGetWhatsappMessageHistory  = (request: IMessageHistoryRequest) =>
  useAppQuery({
    queryKey: ['admin-whatsapp-message-history', request],
    queryFn: () => getMessageHistory(request),
    enabled: !!request?.body?.phone_number,
  })


export const useSendWhatsappMessageMutation = (options?: AppMutationOptions) =>
  useAppMutation(sendWhatsappMessage, {
    useAppMutationProps: options,
  })