import React from "react";
import { marked } from "marked";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserTie } from "@fortawesome/free-solid-svg-icons";
import Logo from "../layout/logo";
import dayjs from "dayjs";

type MyMessageProps = {
  message: string;
  role?: 'guest' | 'admin' | 'remitai';
  name?: string;
  time?: string;
}

const MyMessage: React.FC<MyMessageProps> = (props) => {
  const { message, role = 'guest', name, time } = props;
  const html = marked.parse(message);

  function getTextBetween(str: string, char1: string, char2: string) {
    const start = str.indexOf(char1) + 1;
    const end = str.indexOf(char2, start);
    if (start > 0 && end > start) {
      return str.substring(start, end);
    }
    return ''; // Return empty string if characters are not found or invalid range
  }

  const renderRole = () => {
    switch(role) {
      case 'guest':
        return <FontAwesomeIcon icon={faUser} className="text-gray-1" />
      case 'admin':
        return <FontAwesomeIcon icon={faUserTie} className="text-gray-1" />
      case 'remitai':
        return (
          <p className="w-s-60 text-center">
            <Logo fontSize="text-[8px]" />
          </p>
        )
      default:
        return null
    }
  }

  return (    
    <div className="gap-2 max-sm:pl-11 flex justify-end mb-10 relative">
      <div className="max-sm:w-[100%] w-[60%] flex justify-end">
        <div className="mt-4 bg-main-theme px-5 py-2 text-white rounded-tl-3xl rounded-tr-sm rounded-br-3xl rounded-bl-3xl text-base">
          <div
            className="show-text text-base break-words"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      <div className="h-s-40 w-s-40 rounded-ful flex items-center bg-gray-100 rounded-full justify-center">
        {renderRole()}
      </div>
      {
        (role === 'admin' || role === 'remitai') && (
          <div className="absolute right-12 text-[13px] top-[-4px] text-gray-2 font-semibold">
            {
              role === 'remitai' ? (
                <>Bot - {<span className="font-roboto text-gray-2">{dayjs(time).utc(true).local().format('HH:mm DD/MM')}</span>}</>
              ) : (
                <>{name && getTextBetween(name, '[', ']')} - {<span className="font-roboto text-gray-2">{dayjs(time).utc(true).local().format('HH:mm DD/MM')}</span>}</>
              )
            }
          </div>
        )
      }
    </div>
  )
};

export default MyMessage