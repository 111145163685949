import React from "react";
import MessageLoading from "../message-loading";
import { marked } from "marked";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserTie } from "@fortawesome/free-solid-svg-icons";
import Logo from "../layout/logo";
import dayjs from "dayjs";

const loading = false;

interface IProps {
  message: string;
  role?: 'guest' | 'admin' | 'remitai';
  time: string;
}

export const OtherMessage: React.FC<IProps> = (props) => {
  const { message, role = 'guest', time } = props;
  const html = marked.parse(message);


  const renderRole = () => {
    switch(role) {
      case 'guest':
        return (
          <div className="h-[40px] w-[40px] flex justify-center items-center">
            <FontAwesomeIcon icon={faUser} className="text-gray-1" />
          </div>
        )
      case 'admin':
        return (
          <div className="h-[40px] w-[40px] flex justify-center items-center">
            <FontAwesomeIcon icon={faUserTie} className="text-gray-1" />
          </div>
        )
      case 'remitai':
        return (
          <p className="w-s-60 text-center">
            <Logo fontSize="text-[8px]" />
          </p>
        )
      default:
        return null
    }
  }

  return (
    <>
      <div className="flex max-sm:w-[100%] w-[70%] gap-2 max-sm:pr-11 mb-10 relative">
        <div className="!h-[40px] !w-[40px] rounded-ful flex items-center bg-gray-100 rounded-full justify-center">
          {renderRole()}
        </div>
        {loading ? (
          <MessageLoading />
        ) : (
          <div className="mt-4 bg-gray-100 px-5 py-2 text-black rounded-tl-sm rounded-tr-3xl rounded-br-3xl rounded-bl-3xl">
            <div
              className="show-text text-base break-words" 
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        )}
        {
          (role === 'guest') && (
            <div className="absolute left-12 text-[13px] top-[-4px] text-gray-2 font-semibold">
              <>{<span className="font-roboto text-gray-2">{dayjs(time).utc(true).local().format('HH:mm:ss DD/MM')}</span>}</>
            </div>
          )
        }
      </div>
    </>
  )
}