import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "antd";
import React from "react";

interface IProps {
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

export const ModalConfirm: React.FC<IProps> = ({ title, onCancel, onConfirm, isOpen }) => {
  return (
    <Modal open={isOpen} onCancel={() => onCancel()} footer={null} closeIcon={null} centered width={480} styles={{
      body: {
        padding: '0.5rem',
      }
    }}>
      <div className="text-center font-rale-way">
        <FontAwesomeIcon icon={faTriangleExclamation} className="text-[60px] text-orange-400" />
        <p className="text-[24px] mt-8 font-semibold">
          {title}
        </p>
        <div className="flex w-full gap-4 mt-8">
          <Button type='primary' className="bg-main-theme text-[16px] font-bold h-[48px] flex-1" onClick={() => onConfirm()}>Confirm</Button>
          <Button type='default' className="text-[16px] font-bold h-[48px] flex-1 text-pink-theme-1 border-base-color" onClick={() => onCancel()}>Cancel</Button>
        </div>
      </div>
    </Modal>
  )
}