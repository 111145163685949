import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BaseSearch } from "src/components/form/base-search";
import { useAppSelector } from "src/utils/common";
import { useSwiper } from "swiper/react";
import { Empty } from 'antd';
import { Dropdown } from "./dropdown";
import { useDispatch } from "react-redux";
import { getAdminInboxConversationsSuccess } from "src/store/admin/inbox-conversations/reducers";
interface IProps {
  onChangeConversation: (phone_number: string) => void;
}

export const ConversationList: React.FC<IProps> = ({ onChangeConversation }) => {
  const swiper = useSwiper();
  const conversationData = useAppSelector((state) => state.adminInboxConversations);
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const phoneNumber = searchParams.get('phone');
  const [searchValue, setSearchValue] = useState<string>();

  const searchedConversationData = useMemo(() => {
    if (searchValue) {
      return conversationData?.data?.filter((item) => item.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) || item.phone?.includes(searchValue));
    }
    return conversationData?.data;
  }, [conversationData, searchValue])

  useEffect(() => {
    const phone = searchParams.get('phone');
    phone ? swiper?.slideTo(1) : swiper?.slideTo(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteConversation = (v: string) => {
    const newConversations = conversationData?.data?.filter((item) => item.phone !== v);
    dispatch(getAdminInboxConversationsSuccess(newConversations));
  }

  return (
    <div>
      <p className='text-[26px] font-bold text-pink-theme-1 px-6'>Inbox</p>
      <div className="mt-4 px-6">
        <BaseSearch placeholder="Search..." onChange={(v) => setSearchValue(v)} />
      </div>
      {
        searchedConversationData?.length ? (
          <>
            <div className="px-6 mt-8 overflow-y-auto max-sm:h-[calc(100vh-300px)] h-[500px]">
              {
                searchedConversationData?.map((item, index) => (
                  <div key={index}
                    className={`flex mb-2 gap-3 relative items-center hover:bg-gray-4 py-2 hover:cursor-pointer rounded-md ${phoneNumber === item.phone ? 'bg-gray-4' : ''}`} 
                    onClick={() => {
                      onChangeConversation('this');
                      setSearchParams({
                        name: item.name,
                        phone: item.phone,
                      })
                      swiper?.slideTo(1);
                    }}
                  >
                    <div className="h-[40px] w-[40px] bg-main-theme rounded-full flex items-center justify-center text-[18px] font-bold text-white">
                      {item.name?.charAt(0) ? item.name?.charAt(0)?.toLocaleUpperCase() : 'C'}
                    </div>

                    <div>
                      <p className="text-[14px] font-semibold max-w-[200px] truncate">
                        {item.name}
                      </p>
                      <div className="text-[13px] text-gray-1 truncate max-w-[200px]">{item.phone}</div>
                    </div>

                    <div className="absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Dropdown phoneNumber={item.phone} onDeleteConversation={(v) => handleDeleteConversation(v)} />
                    </div>
                  </div>
                ))
              }
            </div>
          </>
        ) : (
          <>
            <div className="px-6 mt-8 h-[500px] flex items-center justify-center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
            </div>
          </>
        )
      }
      
    </div>
  );
}