import { Button, Form, Modal } from "antd";
import FormItem from "antd/es/form/FormItem";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BaseInput, PasswordInput } from "src/components/form/base-input";
import { AdminAuthLayout } from "src/components/layout/admin-auth-layout";
import Logo from "src/components/layout/logo";
import { getAdminToken } from "src/store/auth/admin-token/actions";
import { IAdminLoginForm } from "src/types/auth";
import { paths, useAppDisPatch, useAppSelector } from "src/utils/common";
import { CHECK_EMAIL, REQUIRED } from "src/utils/rule-form";

export const AdminLoginPage: React.FC = () => {
  const dispatch = useAppDisPatch();
  const navigate = useNavigate();
  const { isLoading, error } = useAppSelector((root) => root.adminToken);

  const onSubmitForm = (v: IAdminLoginForm) => {
    dispatch(getAdminToken(navigate, v));
  }

  return (
    <AdminAuthLayout>
      <Modal
        open={true}
        closeIcon={false}
        footer={null}
        centered
        width={500}
        styles={{
          mask: {
            backgroundColor: 'transparent',
          },
          content: {
            padding: '8px 8px',
          }
        }}
        >
          <div className="bg-white w-full px-8 pb-3 pt-5 rounded-3xl">
            <div className="text-center">
              <Logo fontSize='text-[18px]' />
            </div>
            <div className="text-center text-[32px] font-bold mt-4 mb-8">Admin Login</div>

            <Form layout='vertical' className="font-rale-way" onFinish={onSubmitForm}>
              <FormItem name='email' label='Email' rules={[ REQUIRED, CHECK_EMAIL ]}>
                <BaseInput className="h-[50px] rounded-lg" />
              </FormItem>
              <div className="relative">
                <FormItem className="relative" name='password' label='Password' rules={[ REQUIRED ]}>
                  <PasswordInput className="h-[50px] rounded-lg" />
                </FormItem>
                <Link to='/admin/auth/forgot-password' className="font-medium absolute top-0 right-0 hover:underline cursor-pointer !text-pink-theme-1">Forgot password</Link>
              </div>
              <div>If you don't have account, <Link to={paths.adminSignup} className="hover:underline font-medium cursor-pointer !text-pink-theme-1">Signup here!</Link></div>
              
              {
                error && (
                  <div className="mt-2 text-pink-theme-1">{error}.</div>
                )
              }

              <FormItem>
                <div className="flex justify-center">
                  <Button htmlType="submit" loading={isLoading} className="font-rale-way h-[50px] bg-main-theme w-[160px] font-semibold text-[16px] mt-6" type="primary">Login</Button>
                </div>
              </FormItem>
            </Form>
            
          </div>
      </Modal>
    </AdminAuthLayout>
  )
}