import React, { useEffect, useState } from "react";
import MessageLoading from "../message-loading";
import { marked } from "marked";
import Logo from "../layout/logo";
import { useMutation } from "../../hooks/useMutation";
import AxiosUtils from "../../utils/axios-utils";
import { API } from "../../store/api-route";
import { useAppDisPatch } from "../../utils/common";
import { updateMessages } from "../../store/chat/messages/reducers";
import { IMessageItem } from "../../types/chat";
import { ANONYMOUS_USER_ID } from "../../utils/constant";

type BotMessageProps = {
  index: number;
  messageItem: IMessageItem;
  onChangeBotLoading: (status: boolean) => void;
  isBotLoading: boolean;
};

const BotMessage: React.FC<BotMessageProps> = ({ index, messageItem, onChangeBotLoading, isBotLoading }) => {
  const dispatch = useAppDisPatch();
  const [html, setHtml] = useState<string>('');
  
  const sendMessage = useMutation({
    cb(arg: any) {
      return AxiosUtils.post(API.sendMessage, arg, {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      })
    },
    onSuccess(data) {
      onChangeBotLoading(false);
      dispatch(updateMessages({
        index: index,
        text: data.data.data.answer,
        isGenerated: true,
        isError: false,
      }));
    },
    onError() {
      onChangeBotLoading(false);
      dispatch(updateMessages({
        index: index,
        text: 'Sorry! something went wrong',
        isGenerated: true,
        isError: true,
      }));
    },
  })

  useEffect(() => {
    if (!messageItem.isGenerated) {
      handleStartTyping();
    }
    return () => {
      sendMessage.onCancel();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[messageItem])

  const handleStartTyping = async () => {
    if (messageItem.responseFor) {
      onChangeBotLoading(true);
      sendMessage.mutateFn({
        user_id: ANONYMOUS_USER_ID,
        text: messageItem.responseFor,
        session_id: messageItem.sessionId,
      });
    }
  };

  useEffect(() => {
    setHtml(marked.parse(messageItem.text));
  }, [messageItem])

  return (
    <>
      <div className="flex max-sm:w-[100%] w-[70%] gap-2 max-sm:pr-11  mb-10">
        <div className="h-s-40 w-s-40 rounded-ful flex items-center bg-gray-100 rounded-full justify-center">
          <p className="w-s-60 text-center">
            <Logo fontSize="text-[8px]" />
          </p>
        </div>
        {isBotLoading && sendMessage.isLoading ? (
          <MessageLoading />
        ) : (
          <div className="mt-4 bot-message-content bg-gray-100 px-5 py-2 text-black rounded-tl-sm rounded-tr-3xl rounded-br-3xl rounded-bl-3xl">
            <div
              className="show-text text-base whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BotMessage;
