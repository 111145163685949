import { ThemeConfig } from "antd";

const REQUIRED_MESSAGE = 'This field is required';
const SAME_PASS_MESSAGE = 'Password and confirm password must be the same';
const ONLY_NUMBER_MESSAGE = 'Only number is allowed';

const QUICK_QUESTIONS = [
  {
    title: 'Which countries can I transfer money to? ',
    subTitle: 'ask about remitai service',
  },
  {
    title: 'When will my receiver get my funds?',
    subTitle: 'ask about remitai service',
  },
  {
    title: 'Why can’t I get a refund immediately?',
    subTitle: 'ask about remitai service',
  },
  {
    title: 'Is my data safe with you?',
    subTitle: 'ask about remitai service',
  }
]

const ANTD_THEME_CONFIG: ThemeConfig = {
  components: {
    Button: {
      colorPrimaryBorderHover: 'rgba(255, 108, 125, 1)',
      colorPrimaryHover: 'rgba(255, 108, 125, 1)',
      colorPrimary: 'rgba(255, 108, 125, 1)',
      colorPrimaryActive: 'rgba(255, 108, 125, 1)',
      colorPrimaryTextHover: 'rgba(255, 108, 125, 1)',
    },
    Input: {
      colorBorder: '#d9d9d9',
      activeBorderColor: '#d9d9d9',
      hoverBorderColor: '#d9d9d9',
      colorPrimaryBorder: '#d9d9d9',
      colorError: 'rgba(255, 108, 125, 1)',
      activeShadow: 'none',
    },
    Select: {
      controlItemBgActive: '#d9d9d9',
      colorBorder: '#d9d9d9',
      colorPrimaryBorder: '#d9d9d9',
      colorError: 'rgba(255, 108, 125, 1)',
      colorBorderBg: '#d9d9d9',
      colorInfoBorderHover: '#d9d9d9',
      colorPrimaryBorderHover: '#d9d9d9',
      colorPrimaryActive: '#d9d9d9',
      colorPrimaryHover: '#d9d9d9',
      colorPrimaryBg: '#d9d9d9',
      colorPrimaryTextActive: '#d9d9d9',
      colorPrimary: '#d9d9d9',
      colorPrimaryBgHover: '#d9d9d9'
    },
    DatePicker: {
      colorBorder: '#d9d9d9',
      activeBorderColor: '#d9d9d9',
      hoverBorderColor: '#d9d9d9',
      colorPrimaryBorder: 'white',
      colorError: 'rgba(255, 108, 125, 1)',
      colorPrimary: 'rgba(255, 108, 125, 1)',
      controlItemBgActive: 'rgba(255, 108, 125, 1)',
    },
    Form: {
      colorError: 'rgba(255, 108, 125, 1)',
    },
    Checkbox: {
      colorBgContainer: 'transparent',
      colorPrimary: 'rgba(255, 108, 125, 1)',
      colorTextDisabled: 'rgba(255, 108, 125, 1)',
      controlInteractiveSize: 20,
      marginXS: 8,
      colorPrimaryHover: 'rgba(255, 108, 125, 1)',
    },
    Table: {
      borderRadiusLG: 0,
      controlItemBgActive: 'transparent',
      colorSplit: 'rgba(0, 0, 0, 0.15)',
      controlItemBgActiveHover: `transparent`,
      rowHoverBg: 'transparent',
    },
    Spin: {
      // controlHeight: remToPixels(HEIGHT.xs),
      // controlHeightLG: remToPixels(HEIGHT.sm),
      colorPrimary: 'rgba(255, 108, 125, 1)',
    },
    Modal: {
      // wireframe: true,
      lineHeight: 1.57,
    },
    Tabs: {
      colorPrimaryHover: 'rgba(255, 108, 125, 1)',
      colorPrimary: 'rgba(255, 108, 125, 1)',
      colorPrimaryActive: 'rgba(255, 108, 125, 1)',
      colorTextDisabled: 'rgba(255, 108, 125, 1)',
      colorBorderSecondary: '#f0f0f0',
    },
  },
  token: {
    fontFamily: `"Raleway", serif`
  }
}

const ANONYMOUS_USER_ID = 'anonymous';

const DATE_FORMAT = {
  MESSAGE_TIME: 'HH:mm',
}

enum RESPONSE_CODE {
  SUCCESS = 200,
  UNAUTHORIZED = 401,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  PERMISSION = 403,
  SERVER_ERROR = 502,
  VALIDATION_ERROR = 422,
}

export {
  REQUIRED_MESSAGE,
  SAME_PASS_MESSAGE,
  ONLY_NUMBER_MESSAGE,
  QUICK_QUESTIONS,
  ANTD_THEME_CONFIG,
  ANONYMOUS_USER_ID,
  DATE_FORMAT,
  RESPONSE_CODE,
}