import { faAngleLeft, faCircleExclamation, faCloudMeatball, faUpLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Skeleton } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Scroll from "react-scroll";
import MyMessage from "src/components/my-message";
import { OtherMessage } from "src/components/other-message";
import { useGetWhatsappMessageHistory, useSendWhatsappMessageMutation } from "src/hooks/features/useInbox";
import { addAdminInboxConversation } from "src/store/admin/inbox-conversations/reducers";
import { IAdminInboxMessage, IMessageHistoryRequest } from "src/types/admin/inbox";
import { useAppDisPatch, useAppSelector } from "src/utils/common";
import { useSwiper } from "swiper/react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from "react-toastify";
import { getAdminSession } from "src/utils/admin-session";
import { EventSource } from 'eventsource';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const Element = Scroll.Element;
const scroller = Scroll.scroller;

const INITIAL_OFFSET = 0;

const initialParams = {
  page: 1,
  page_size: 15,
  offset: INITIAL_OFFSET,
}

export const MessageList: React.FC = () => {
  const [searchParams] = useSearchParams();
  const phoneNumber = searchParams.get('phone');
  const userName = searchParams?.get('name');
  
  const [content, setContent] = useState<string>();
  const [messageList, setMessageList] = useState<IAdminInboxMessage[]>([]);
  const adminDetail = useAppSelector((state) => state.adminDetail);
  const swiper = useSwiper();
  const [historyRequest, setHistoryRequest] = useState<IMessageHistoryRequest>({
    body: { phone_number: null },
    params: initialParams,
  });
  const [historyHasMore, setHistoryHasMore] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(INITIAL_OFFSET);

  const { data: messageHistory, isLoading: messageHistoryLoading } = useGetWhatsappMessageHistory(historyRequest);
  const { mutate: sendWhatsappMutate, isPending: sendMessageLoading } = useSendWhatsappMessageMutation();
  const [limit24h, setLimit24h] = useState<boolean>(false);

  const messageHistoryMemo = useMemo(() => {

    if (messageHistory?.data?.data?.values && messageHistory?.data?.data?.values?.length < initialParams?.page_size) {
      setHistoryHasMore(false);
    }
    return messageHistory?.data?.data?.values ?? [];
  }, [messageHistory?.data?.data]);

  const conversationData = useAppSelector((state) => state.adminInboxConversations);
  const dispatch = useAppDisPatch();

  const scrollToLastMessage = () => {
    scroller.scrollTo(`lastElement`, {
      duration: 0,
      delay: 0,
      containerId: 'inboxChatContainer',
      offset: 1000,
    });
  }

  useEffect(() => {
    setHistoryHasMore(true);
    phoneNumber && setHistoryRequest({
      body: { phone_number: phoneNumber },
      params: initialParams,
    })
    setOffset(INITIAL_OFFSET);
  }, [phoneNumber]);

  useEffect(() => {
    if (historyRequest?.params?.page === 1) {
      setMessageList( messageHistoryMemo ?? []);
      setOffset(INITIAL_OFFSET);
    } else {
      setMessageList((prev) => [
        ...prev,
        ...(messageHistoryMemo ?? [])
      ])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageHistoryMemo]);

  useEffect(() => {
      // not found in conversation list;
      const foundConversation = conversationData?.data?.find((item) => item.phone === phoneNumber);
      if (!foundConversation && phoneNumber) {
        dispatch(addAdminInboxConversation({
          name: userName,
          phone: phoneNumber,
        }))
      } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSendMessage = (message: string) => {
    if (phoneNumber) {
      sendWhatsappMutate({
        phone_number: phoneNumber,
        message: message,
      }, {
        onSuccess() {
          setMessageList(
            (prev) => [{
              role: `admin[${adminDetail?.data?.email}]`,
              content: message,
              created_at: dayjs().utc().format('YYYY-MM-DD HH:mm:ss'),
            }, ...prev
          ]);
          setContent('');
          setTimeout(() => {
            scrollToLastMessage();
          }, 1);
          setOffset((prev) => prev + 1);
        },
        onError(err) {
          toast.error('Send message failed!');
        }
      });
    }
  }

  const onFetchNextPage = () => {
    setHistoryRequest((prev) => ({
      ...prev,
      params: {
        page: (prev?.params?.page ?? 1) + 1,
        page_size: initialParams?.page_size,
        offset: offset,
      }
    }))
  }

  useEffect(() => {
    if (phoneNumber) {
      const session = getAdminSession();
      
      const es = new EventSource(`${process.env.REACT_APP_BACK_END_BASE_URL}admins/events/${phoneNumber}`, {
        fetch: (input, init) =>
          fetch(input, {
            ...init,
            headers: {
              ...init?.headers,
              'x-access-token': session.access_token,
            },
          }),
      });

      es.addEventListener('message', (event) => {
        try {
          const message = JSON.parse(event.data);
          setMessageList(
            (prev) => [{
              role: message.role ?? '',
              content: message.content ?? '',
              created_at: message?.created_at ?? '',
            }, ...prev
          ]);
          setOffset((prev) => prev + 1);
        } catch(err) {
          toast.error('Received message failed!');
        }
      })

      return () => {
        es.close();
      };
    }

  }, [phoneNumber]);


  useEffect(() => {
    if (messageList?.length) {
      let lastUserMessage = messageList.find((item) => {
        return item.role === 'user';
      });
      if (!lastUserMessage) {
        lastUserMessage = messageList[0];
      }
      if (dayjs().subtract(24, 'hour').isBefore(dayjs(lastUserMessage?.created_at).utc(true))) {
        setLimit24h(false);
      } else {
        setLimit24h(true);
      }
    }
  }, [messageList]);

  return (
    <div className="relative h-full">
      {
        phoneNumber ? (
          <div className="flex gap-2">
            <div className="h-[48px] max-xl:flex hidden items-center justify-center">
              <FontAwesomeIcon icon={faAngleLeft} className='text-gray-2 cursor-pointer' onClick={() => swiper?.slideTo(0)} />
            </div>
            <div className="flex gap-2 flex-1">
              <div className="w-[48px] h-[48px] bg-main-theme flex items-center justify-center rounded-full font-bold text-white text-[28px]">
                {userName?.charAt(0) ? userName?.charAt(0)?.toLocaleUpperCase() : 'C'}
              </div>
              <div>
                <p className="text-[18px] font-bold truncate max-w-[200px]">{userName}</p>
                <p className="text-[14px] mt-1 text-gray-1">{phoneNumber}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-[48px] w-[48px]"></div>
        )
      }
      
      <Divider className="mt-3 mb-2" />

      {
        phoneNumber ? (
          <>
            {
              messageHistoryLoading && historyRequest?.params?.page === 1 ? (
                <div className="h-[648px]">
                  <Skeleton active={true} style={{ width: '100%', height: 648 }}></Skeleton>
                </div>
              ) : (
                <div
                  className='flex flex-col-reverse max-sm:h-[calc(100vh-300px)] overflow-y-auto h-[468px] w-[100%] max-xl:w-[100%] max-lg:w-[100%] max-md:w-[100%] max-md:px-4 max-sm:px-2 pr-2' id='inboxChatContainer'
                >
                  <InfiniteScroll
                    dataLength={(messageList?.length ?? 0) + 1}
                    next={onFetchNextPage}
                    style={{ display: 'flex', flexDirection: 'column-reverse', overflow: 'hidden' }} //To put endMessage and loader to the top.
                    inverse={true}
                    hasMore={historyHasMore}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="inboxChatContainer"
                  >
                    <Element name='lastElement' />
                    {messageList.map((item: IAdminInboxMessage, index: number) => {

                      switch(item.role) {
                        case 'user': 
                          return (
                            <div key={index} className="pt-2">
                              <OtherMessage message={item.content} time={item.created_at} />
                            </div>
                          )
                        case 'remitai':
                          return (
                            <div key={index} className="pt-2">
                              <MyMessage message={item.content} role="remitai" name={item.role} time={item.created_at} />
                            </div>
                          )
                        default: 
                          return (
                            <div key={index} className="pt-2">
                              <MyMessage message={item.content} role='admin' name={item.role} time={item.created_at} />
                            </div>
                          )
                      }
                    })}
                  </InfiniteScroll>
                </div>
              )
            }
          </>
        ): (
          <>
             <div className="h-[468px] flex items-center justify-center flex-col">
                <div>
                  <FontAwesomeIcon icon={faCloudMeatball} className="text-[120px] text-[#f2f1f4]" />
                </div>
                <div className="mt-2 text-gray-2 text-[19px]">Select a conversation to continue</div>
              </div>
          </>
        )
      }

      <div className="absolute bottom-0 left-0 w-full pe-1 bg-white">
        {
          !limit24h && (
            <Divider className="my-0 "/>
          )
        }

        {
          limit24h ? (
            <div className=" flex gap-2 bg-[#f8b0251c] rounded-lg px-4 py-2 items-center" style={{ border: '1px solid #f8b025a6' }}>
              <div>
                <FontAwesomeIcon icon={faCircleExclamation} className="text-[#f8b025a6]" />
              </div>
              <div className="max-sm:text-[14px]">
                24 hours limit. Whatsapp does not allow sending messages to a user 24 hours after they last messaged you.
              </div>
            </div>
          ) :
          (
            <>
              <div className="flex items-end justify-between bg-white rounded-lg min-w-[200px] mx-auto">
                <TextArea
                  className="bg-white !outline-none !border-none m-0 pt-4 pb-2 w-full text-base resize-none focus:ring-0 focus-visible:ring-0 dark:bg-transparent placeholder-black/50 dark:placeholder-white/50"
                  placeholder="Message..."
                  autoSize={{ minRows: 1, maxRows: 20  }}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 && !e.shiftKey) {
                      e.preventDefault();
                      const trimContent = content?.trim();
                      if (trimContent)
                        onSendMessage(trimContent);
                    }
                  }}
                />
                <div className="pt-4 px-4">
                  <Button
                    type='primary'
                    className="bg-main-theme"
                    disabled={!content?.trim()}
                    loading={sendMessageLoading}
                    onClick={() => {
                      const trimContent = content?.trim();
                      if (trimContent)
                        onSendMessage(trimContent);
                    }}
                    >
                    <FontAwesomeIcon icon={faUpLong} className="text-md" />
                  </Button>
                </div>
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}