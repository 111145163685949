import React from "react";
import { AdminLayout } from "src/components/layout/admin-layout";
import { ConversationList } from "./conversation-list";
import { MessageList } from "./message-list";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useResponsive } from "src/hooks/useResponsive";
export const AdminInboxPage: React.FC = () => {
  const { isLaptop } = useResponsive();

  return (
    <AdminLayout>
      <>
        {
          !isLaptop ? (
            <>
              <div className="bg-white rounded-xl shadow-primary max-sm:rounded-none max-sm:shadow-none">
                <Swiper>
                  <SwiperSlide>
                    {(props) => (
                      <div className="h-[680px] max-sm:h-[calc(100vh-128px)]">
                        <div className='py-8 h-full max-sm:pt-8 pb-0'>
                          <ConversationList
                            onChangeConversation={() => {
                              // console.log(swiper);
                              // swiper.slideTo(1);
                            }}
                          />
                        </div>
                      </div>
                    )}
                    
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="h-[680px] max-sm:h-[calc(100vh-128px)]">
                      <div className='px-6 py-8 h-full'>
                        <MessageList />
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </>
          ) :
          (
            <>
              <div className="flex gap-6 h-[680px]">
                {/* converations list */}
                <div className='bg-white shadow-primary rounded-xl py-8 w-[380px] h-full'>
                  <ConversationList
                    onChangeConversation={() => null}
                  />
                </div>

                {/* message list */}
                <div className='bg-white shadow-primary rounded-xl px-6 py-8 flex-1'>
                  <MessageList />
                </div>
              </div>
            </>
          )
        }

      </>
    </AdminLayout>
  )
}

