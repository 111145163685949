import React from 'react';
import {  marked } from 'marked';
import SignInPage from './pages/sign-in';
import SignUpPage from './pages/sign-up';
import ChatPage from './pages/chat';
import { AUTHORIZE, IRouteObject } from './types/route';
import { Route, Routes } from 'react-router-dom';
import { paths } from './utils/common';
import ProtectedRoute from './routes/protected-route';
import { PrivacyPolicyPage } from './pages/privacy-policy';
import { TermsOfUsePage } from './pages/terms-of-use';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { MessageMangement } from './pages/admin/message-management';
import { AdminManagement } from './pages/admin/admin-management';
import { MessageDetailPage } from './pages/admin/message-detail';
import { AdminInboxPage } from './pages/admin/inbox';
import { AdminLoginPage } from './pages/admin/auth/login';
import { AdminForgotPasswordPage } from './pages/admin/auth/forgot-password';
import { AdminSignupPage } from './pages/admin/auth/signup';
import AdminProtectedRoute from './routes/admin-protected-route';
import AdminPublicRoute from './routes/admin-public-route';
import { NotFoundPage } from './pages/not-found';
import { HomePage } from './pages/home';
import { AdminPage } from './pages/admin';
import { AdminResetPasswordPage } from './pages/admin/auth/reset-password';
import { AdminAcccountSettingPage } from './pages/admin/account-setting';

const queryClient = new QueryClient();

function App() {
  const mainRoutes: IRouteObject[] = [
    {
      path: paths.getStart,
      component: SignInPage,
      isPublic: true,
    },
    {
      path: '/home',
      component: HomePage,
      isPublic: true,
    },
    {
      path: paths.signUpAtlas,
      component: SignUpPage,
      isPublic: true,
    },
    {
      path: paths.privacyPolicy,
      component: PrivacyPolicyPage,
      isPublic: true,
    },
    {
      path: paths.termsOfUse,
      component: TermsOfUsePage,
      isPublic: true,
    },
    {
      path: paths.home,
      component: ChatPage,
      isPublic: false,
      authorize: AUTHORIZE.GUEST,
    },
    {
      path: '/chat/c/:id',
      component: ChatPage,
      isPublic: false,
      authorize: AUTHORIZE.GUEST,
    },
    {
      path: '/admin',
      component: AdminPage,
      isPublic: false,
      authorize: AUTHORIZE.ADMIN,
    },
    {
      path: '/admin/message-management',
      component: MessageMangement,
      isPublic: false,
      authorize: AUTHORIZE.ADMIN,
    },
    {
      path: '/admin/message-management/:id',
      component: MessageDetailPage,
      isPublic: false,
      authorize: AUTHORIZE.ADMIN,
    },
    {
      path: '/admin/admin-management',
      component: AdminManagement,
      isPublic: false,
      authorize: AUTHORIZE.ADMIN,
    },
    {
      path: '/admin/inbox',
      component: AdminInboxPage,
      isPublic: false,
      authorize: AUTHORIZE.ADMIN,
    },
    {
      path: '/admin/auth/login',
      component: AdminLoginPage,
      isPublic: true,
      authorize: AUTHORIZE.ADMIN,
    },
    {
      path: '/admin/auth/forgot-password',
      component: AdminForgotPasswordPage,
      isPublic: true,
      authorize: AUTHORIZE.ADMIN,
    },
    {
      path: '/admin/auth/signup',
      component: AdminSignupPage,
      isPublic: true,
      authorize: AUTHORIZE.ADMIN,
    },
    {
      path: '/admin/account-setting',
      component: AdminAcccountSettingPage,
      isPublic: false,
      authorize: AUTHORIZE.ADMIN,
    }
  ]

  const renderer = {
    code(text: any, level: any) {
      return `<div style="margin-bottom: 8px; background: #282a36; color: #ffb86c; font-family: 'Roboto', sans-serif; font-weight: 400; border-radius: 6px"><div style="background: gray; height: 24px; width: 100%; border-radius: 6px 6px 0 0"></div><div style="padding: 12px;">${text}</div></div>`;
    },
    link(href: any, title: any, text: string) {
      return `<a target="_blank" href=${href} class='!text-[#000080] underline break-all'>${text}</a>`
    }
  };
  marked.use({ renderer });

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App h-auto w-full font-roboto">
        <Routes>
          {
            mainRoutes.map((route, index) => {
              const Component = route.component;
              if (!route.isPublic && route.authorize === AUTHORIZE.GUEST) {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <ProtectedRoute>
                        <Component />
                      </ProtectedRoute>
                    }
                  />
                );
              } else if (!route.isPublic && route.authorize === AUTHORIZE.ADMIN) {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <AdminProtectedRoute>
                        <Component />
                      </AdminProtectedRoute>
                    }
                  />
                )
              } else if (route.isPublic && route.authorize === AUTHORIZE.ADMIN) {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                    <AdminPublicRoute>
                      <Component />
                    </AdminPublicRoute>
                    }
                />
                )
              } else if (route.isPublic) {
                return <Route key={index} path={route.path} element={<Component />} />
              }
            })
          }

          <Route path='/*' element={<NotFoundPage />} />
        </Routes>
      </div>
    </QueryClientProvider>
  );
}

export default App;
