import { ApiError } from 'src/types/common';
import { FormInstance } from 'antd';

import { useAppMutationCustomProps } from './useAppMutation';
import { RESPONSE_CODE } from 'src/utils/constant';

type ARGS = {
  error: ApiError;
  form?: FormInstance;
  customProps?: useAppMutationCustomProps;
};

export default function useLoadServerError() {

  const loadServerErrors = (args: ARGS) => {
    const { error, form, customProps } = args;

    if (!error) {
      return;
    }

    // if (error.status == RESPONSE_CODE.UNAUTHORIZED) {
    //   dispatch(removeCredentials());
    // }

    const isClientError = !Object.prototype.hasOwnProperty.call(error, 'response');

    if (isClientError && customProps?.toast && error.status !== RESPONSE_CODE.VALIDATION_ERROR) {
      // const response = error.data as ServerError;
      // notification.error({ message: response?.message });
      return;
    }

    if (error.status === RESPONSE_CODE.SERVER_ERROR) {
      // show toast notification
      // notification.error({ message: 'Server error has occurred. Please try again later.' });
      return;
    }

    if (form !== undefined && error.status === RESPONSE_CODE.VALIDATION_ERROR) {
      // const data = error.data as ValidationError;
      return;
    }
  };

  return {
    loadServerErrors,
  };
}
