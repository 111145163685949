import { getConversationList, getSupportNeededConversation, resolveConversation, searchConversationList } from "src/api/adminMessageManagement";
import useAppQuery from "../useAppQuery";
import { IConversationListRequest, ISearchConversationListRequest } from "src/types/admin/message-management";
import { IPaginationParams } from "src/types/common";
import useAppMutation, { AppMutationOptions } from "../useAppMutation";

export const useGetConversationListQuery  = (params: IConversationListRequest) =>
  useAppQuery({
    queryKey: ['conversation-list', params],
    queryFn: () => getConversationList(params),
  })

export const useGetSupportNeededConversationListQuery  = (params: IConversationListRequest) =>
  useAppQuery({
    queryKey: ['support_needed_conversation-list', params],
    queryFn: () => getSupportNeededConversation(params),
  })

export const useGetSearchConversationListQuery = (data: {
  searchValue: ISearchConversationListRequest | null,
  params: IPaginationParams,
}) =>
  useAppQuery({
    queryKey: ['search_conversation-list', data],
    queryFn: () => searchConversationList(data.searchValue, data.params),
  })

export const useResolveConversationMutation = (options?: AppMutationOptions) =>
  useAppMutation(resolveConversation, {
    useAppMutationProps: options,
  })