import { faEllipsisVertical, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Dropdown as AntDropdown, Button } from "antd";
import { useCheckClickOutside } from "src/hooks/useCheckClickOutSide";

interface IProps {
  phoneNumber: string;
  onDeleteConversation: (v: string) => void;
}

export const Dropdown: React.FC<IProps> = ({ phoneNumber, onDeleteConversation }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<any>();
  
  useCheckClickOutside(() => {
    setIsOpen(false);
  }, ref, [])

  return (
    <AntDropdown
      open={isOpen}
      dropdownRender={() => (
        <div className="bg-white p-2 rounded-md w-[120px] shadow-primary" ref={ref}>
          <Button
            className="p-2 h-auto border-none w-full text-left !text-pink-theme-1"
            type='text'
            onClick={() => {
              onDeleteConversation(phoneNumber);
              setIsOpen(false);
            }}>
              <FontAwesomeIcon icon={faTrashCan} className="me-2 text-base w-s-20" />
              Delete
          </Button>
        </div>
      )}
    >
      <div onClick={() => setIsOpen(true)} className="w-[40px] h-[40px] flex items-center justify-center">
        <FontAwesomeIcon icon={faEllipsisVertical} className="!text-gray-1" />
      </div>
    </AntDropdown>
  )
};
