import React from "react";
import facebook from 'src/assets/svg/facebook.svg';
import instagram from 'src/assets/svg/instagram.svg';
// import twitter from 'src/assets/svg/twitter.svg';
// import linkedin from 'src/assets/svg/linkedin.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareThreads } from "@fortawesome/free-brands-svg-icons";

type Props = {
  children: React.ReactElement | React.ReactElement[];
}

const AuthenLayout: React.FC<Props> = ({children}) => {

  return (
    <div className="w-full flex flex-col bg-[#f6f6f6]">
      {children}

      <div className="bg-main-theme w-100% md:mx-8 md:rounded-2xl rounded-none md:mb-6 mb-0 text-white flex py-8 md:flex-row flex-col gap-8 md:gap-0">
        <div className="flex-1 flex justify-center md-gap-4 gap-2 md:flex-row flex-col items-center font-cabin text-[14px]">
          <p>© 2024 remit.ai</p>
          <a href="/privacy-notice" target="_blank" rel="noreferrer">
            <p className="hover:underline cursor-pointer">Privacy Policy</p>
          </a>
          <a href="/terms-and-conditions" target="_blank" rel="noreferrer">
            <p className="hover:underline cursor-pointer">Terms of Use</p>
          </a>
        </div>
        <div className="flex-1 flex justify-center gap-4">
          <a href="https://www.facebook.com/61570026646881" target="_blank" rel="noreferrer">
            <img src={facebook} alt='facebook' />
          </a>
          <a href="https://www.instagram.com/remit.ai/" target="_blank" rel="noreferrer">
            <img src={instagram} alt='instagram' />
          </a>
          <a href="https://www.threads.net/@remit.ai" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faSquareThreads} className="text-[#ffeded] text-[1.4rem]" />
          </a>
        </div>
      </div>
    </div>
  )
};

export default AuthenLayout;