import { Row, Table } from "antd";
import React from "react";
import { ModalConfirm } from "src/components/modal/modal-confirm";
import { useAllConversations } from "./index.utils";
import { DetailConversationModal } from "../detail-conversation-modal";
import { BasePagination } from "src/components/form/pagination";

export const SupportNeededConversations = () => {

  const {
    columns,
    isOpenConfirmDelete,
    dataSource,
    conversationListLoading,
    expandedRowKeys,
    isOpenDetailConversation,
    total,
    currentPage,
    pageSize,
    onChangePage,
    setIsOpenDetailConversation,
    expandedRowRender,
    setEditingKey,
    setExpandedRowKeys,
    setIsOpenConfirmDelete 
  } = useAllConversations()

  const expandableProps = expandedRowRender
  ? {
      expandable: {
        expandedRowRender: expandedRowRender,
        expandedRowKeys: expandedRowKeys,
        onExpand: (expanded: boolean, record: any) => {
          if (expanded) {
            setExpandedRowKeys && setExpandedRowKeys([record.key]);
            setEditingKey && setEditingKey(record.key);
          } else {
            setExpandedRowKeys && setExpandedRowKeys([]);
            setEditingKey && setEditingKey('');
          }
        },
        showExpandColumn: false,
      },
    }
  : {};

  return (
    <>

      <Row gutter={[12, 12]} className='mb-4'>
      </Row>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={conversationListLoading}
        pagination={false}
        scroll={{ x: 'max-content' }}
        {...expandableProps}
      />

      <BasePagination
        current={currentPage}
        pageSize={pageSize}
        total={total}
        onChange={(page) => onChangePage(page ?? 1)}
      />

      <DetailConversationModal open={isOpenDetailConversation} onCancel={() => setIsOpenDetailConversation(false)} />

      <ModalConfirm
        isOpen={isOpenConfirmDelete}
        onCancel={() => setIsOpenConfirmDelete(false)}
        title='Are you sure to delete these records!'
        onConfirm={() => setIsOpenConfirmDelete(false)}
      />
    </>
  )
}