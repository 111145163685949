import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { BaseSearch } from "src/components/form/base-search";
import { ISearchConversationListRequest } from "src/types/admin/message-management";
import { DatePicker } from 'antd';
import  { Dayjs } from "dayjs";

const { RangePicker } = DatePicker;

interface IProps {
  onSearch: (v: ISearchConversationListRequest | null) => void;
  searchPayload: ISearchConversationListRequest | null;
}

export const Search: React.FC<IProps> = ({ onSearch, searchPayload }) => {
  const [session, setSession] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null] | null>(null);

  const handleSearch = () => {
    if (session || phoneNumber || content || dateRange) {
      onSearch({
        ...(session ? {session_id: session} : {}),
        ...(phoneNumber ? {phone_number: phoneNumber} : {}),
        ...(content ? {content: content} : {}),
        ...(dateRange ? {start_time: dateRange[0]?.format('YYYY-MM-DD')} : {}),
        ...(dateRange ? {end_time: dateRange[1]?.format('YYYY-MM-DD')} : {})
      })
    } else {
      onSearch(null)
    }
  }

  const onReset = () => {
    setSession('');
    setPhoneNumber('');
    setContent('');
    onSearch(null);
  }

  return (
    <Row gutter={[12, 12]} className='mb-4'>
    <Col>
      <BaseSearch placeholder='Session ID...' onChange={(v) => setSession(v)} value={session} />
    </Col>
    <Col>
      <BaseSearch placeholder='Phone Number...' onChange={(v) => setPhoneNumber(v)} value={phoneNumber} />
    </Col>
    <Col>
      <BaseSearch placeholder='Content...' onChange={(v) => setContent(v)} value={content} />
    </Col>
    <Col>
      <RangePicker value={dateRange} onChange={(date) => setDateRange(date)} className="h-[40px] !bg-[#f2f1f4] !shadow-none border-none" />
    </Col>
    <Col>
      <Row gutter={[12, 12]}>
        <Col>
          <Button onClick={handleSearch} type="primary" className="h-[40px] !px-3 text-pink-theme-1 bg-[#ff6c7d1a] font-semibold border-base-color shadow-none">Search</Button>
        </Col>
        {
          searchPayload && (
            <Col>
              <Button onClick={onReset} type="primary" className="h-[40px] !px-3 text-pink-theme-1 bg-[#ff6c7d1a] font-semibold border-base-color shadow-none">Reset</Button>
            </Col>
          )
        }
      </Row>
    </Col>
  </Row>
  )
}