import { Pagination } from "antd";
import React, { useEffect } from "react";

interface IProps {
  onChange: (v: number) => void;
  current: number;
  total: number;
  pageSize?: number;
}

export const BasePagination: React.FC<IProps> = (props) => {
  const { current, onChange, total, pageSize = 10 } = props;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [current]);

  return (
    <div className="flex justify-center">
      <Pagination
        showSizeChanger={false}
        current={current}
        onChange={onChange}
        total={total}
        pageSize={pageSize}
      />
    </div>
  )
}