import { combineReducers } from 'redux';
import getTokenReducers from './auth/get-token/reducers';
import signUpReducers from './auth/sign-up/reducers';
import sendMessageReducers from './chat/send-message/reducers';
import messagesReducers from './chat/messages/reducers';
import conversationsReducers from './chat/conversations/reducers';
import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import idTypeReducer from './common/id-type/reducers';
import countryListReducer from './common/country-list/reducers';
import occupationListReducer from './common/occupation-list/reducers';
import sourceOfFundListReducer from './common/source-of-fund-list/reducers';
import getLoginUrlReducer from './singpass/get-login-url/reducers';
import singpassMockDataReducer from './singpass/get-sample-data/reducers'
import adminDetailReducer from './admin/admin-detail/reducers';
import adminTokenReducer from './auth/admin-token/reducers';
import customerConversationDetail from './admin/customer-conversation-detail/reducers';
import adminInboxConversationReducer from './admin/inbox-conversations/reducers';

const conversationsPersist = {
  key: 'conversations',
  storage
}

const adminDetailPersist = {
  key: 'adminDetail',
  storage
}

const adminInboxConversationsPersist = {
  key: 'adminInboxConversationsPersist',
  storage,
}

const rootReducer = combineReducers({
  getToken: getTokenReducers,
  signUp: signUpReducers,
  sendMessage: sendMessageReducers,
  messages: messagesReducers,
  conversations: persistReducer(conversationsPersist, conversationsReducers),
  idType: idTypeReducer,
  countryList: countryListReducer,
  occupationList: occupationListReducer,
  sourceOfFundList: sourceOfFundListReducer,
  loginUrl: getLoginUrlReducer,
  singpassMockData: singpassMockDataReducer,
  adminDetail: persistReducer(adminDetailPersist, adminDetailReducer),
  adminToken: adminTokenReducer,
  customerConversationDetail: customerConversationDetail,
  adminInboxConversations: persistReducer(adminInboxConversationsPersist, adminInboxConversationReducer),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }),
})

export const persistor = persistStore(store)
