import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faGear, faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { Button, Divider, Drawer, Popover } from "antd";
import { useAppSelector } from "src/utils/common";
import { AdminSideBar } from "./side-bar";
import { deleteAdminSession } from "src/utils/admin-session";
import { useNavigate } from "react-router";

interface IProps {
  children: React.ReactElement;
}

export const AdminLayout: React.FC<IProps> = ({ children }) => {
 
  const { data: adminDetail } = useAppSelector((state) => state.adminDetail);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const navigate = useNavigate();

  const onLogout = () => {
    deleteAdminSession();
    window.location.reload();
  }

  return (
    <div className="font-rale-way bg-gray-4 max-sm:bg-white min-h-[100vh]">

      {/* HEADER */}
      <div className="fixed w-full h-[64px] shadow-primary z-10 bg-white">
        <div className="flex justify-between pr-16 py-2 h-full items-center max-sm:pr-6 max-sm:ps-6">
          <div>
            <div className="hidden max-sm:block">
              <FontAwesomeIcon icon={faBars} className="cursor-pointer text-gray-1" onClick={() => setIsOpenDrawer(true)} />
            </div>
          </div>
          <div className="flex gap-4 h-full">
            <div className="flex items-center gap-2">
              <div className="h-8 w-8 bg-main-theme text-white font-bold flex items-center justify-center rounded-full">
                {Array.from(adminDetail?.first_name ?? 'A')[0]}
              </div>
              <div>
                {adminDetail?.first_name + ' ' + adminDetail?.last_name}
              </div>
            </div>
            <div className="h-full w-[1px] bg-gray-3"></div>
            <div className="flex items-center justify-center">
              <Popover
                trigger={'click'}
                content={() => (
                  <div>
                    <Button className="border-none font-medium text-gray-1 flex items-center" onClick={() => navigate('/admin/account-setting')}>
                      <FontAwesomeIcon icon={faUser} className="text-[16px]" />
                      <span className="ms-2">Account setting</span>
                    </Button>
                    <Divider className="my-1"/>
                    <Button className="border-none font-medium text-gray-1 flex items-center" onClick={onLogout}>
                      <FontAwesomeIcon icon={faRightFromBracket} className="text-[16px]" />
                      <span className="ms-2">Log out</span>
                    </Button>
                  </div>
                )}
              >
                <FontAwesomeIcon icon={faGear} className="text-[16px] text-gray-1 cursor-pointer" />
              </Popover>
            </div>
          </div>
        </div>
      </div>

      {/* SIDE BAR */}
      <div className="fixed h-full shadow-primary z-20 bg-white w-[320px] max-sm:hidden max-xl:w-[320px] max-lg:w-[60px] overflow-x-hidden overflow-y-hidden">
        <AdminSideBar />
      </div>

      {/* MOBILE */}
      <Drawer
        placement={'left'}
        closable={false}
        onClose={() => setIsOpenDrawer(false)}
        open={isOpenDrawer}
        key={'left_lef'}
        width={300}
        styles={{
          body: {
            padding: 0
          }
        }}
        rootClassName="max-sm:block hidden"
      >
        <AdminSideBar />
      </Drawer>

      {/* CONTENT */}
      <div
        className="pt-[92px] pr-6 pe-8 ps-[348px] max-xl:ps-[348px] max-lg:ps-[84px] max-sm:ps-0 max-sm:pr-0 max-sm:pt-[64px] pb-6 max-sm:pb-0"
      >
        {children}
      </div>
    </div>
  )
}