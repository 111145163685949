import { Tabs, TabsProps } from 'antd';
import React, { useState } from 'react';
import { AdminLayout } from 'src/components/layout/admin-layout';
import { AllConversations } from './all-conversations';
import { SupportNeededConversations } from './support-needed-conversations';
import { useSearchParams } from 'react-router-dom';

enum MESSAGE_MANAGEMENT_TAB {
  ALL_CONVERSATIONS = 'all_conversations',
  SUPPORT_NEEDED_CONVERSATIONS = 'support_needed_conversations',
}

export const MessageMangement: React.FC = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const items: TabsProps['items'] = [
    {
      key: MESSAGE_MANAGEMENT_TAB.ALL_CONVERSATIONS,
      label: 'All Conversations',
    },
    {
      key: MESSAGE_MANAGEMENT_TAB.SUPPORT_NEEDED_CONVERSATIONS,
      label: 'Support Needed Conversations',
    },
  ];

  const [activeTab, setActiveTab] = useState<MESSAGE_MANAGEMENT_TAB>((tab as MESSAGE_MANAGEMENT_TAB) ?? MESSAGE_MANAGEMENT_TAB.ALL_CONVERSATIONS);

  return (
   <AdminLayout>
    <div className='bg-white shadow-primary rounded-xl px-6 py-8 max-sm:rounded-none'>
      <p className='text-[20px] font-semibold text-light-dark'>Message Management</p>
      <div className='mt-8'>

        <Tabs activeKey={activeTab} items={items} className='mb-8' onChange={(key) => {
          setActiveTab(key as MESSAGE_MANAGEMENT_TAB);
          setSearchParams({ tab: key })
        }} />
        
        {
          activeTab === MESSAGE_MANAGEMENT_TAB?.ALL_CONVERSATIONS ? 
          (
            <AllConversations />
          ) : 
          (
            <SupportNeededConversations />
          )
        }
      </div>

    </div>
   </AdminLayout>
  );
};
