import React, { ReactElement, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Session } from 'src/types/common';
import { paths, useAppDisPatch } from 'src/utils/common';
import { getAdminSession } from 'src/utils/admin-session';
import { getAdminDetail } from 'src/store/admin/admin-detail/actions';

interface Props {
  children: ReactElement;
}

const AdminProtectedRoute = ({ children }: Props) => {
  const dispatch = useAppDisPatch();
  const session: Session = getAdminSession();

  useEffect(() => {
  const session: Session = getAdminSession();
    if (session?.access_token) {
      dispatch(getAdminDetail());
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])  

  if (!session || !session.access_token) {
    return <Navigate to={paths.adminLogin} />;
  }

  return children;
};

export default AdminProtectedRoute;