import React from "react";
import { BaseSearch } from "../form/base-search";
import { Button } from "antd";

interface IProps {
  buttons: {
    icon: React.JSX.Element,
    title: string;
    disabled: boolean;
    onClick: () => void;
  }[];
  showSearch?: boolean;
}

export const TableAction: React.FC<IProps> = ({ buttons, showSearch = true }) => {

  return (
  <div className="flex justify-end gap-3 mb-3 mt-3">
    {
      showSearch && (
        <div className="w-[300px]">
          <BaseSearch onChange={() => null} />
        </div>
      )
    }
    {
      buttons.map((item, index) => (
        <Button key={index} type="primary" className="!flex !items-center gap-2 h-[40px] !px-3 text-pink-theme-1 bg-[#ff6c7d1a] font-semibold border-base-color shadow-none" disabled={item.disabled} onClick={() => item.onClick()}>
          <div>
            {item.icon}
          </div>
          <div>
            {item.title}
          </div>
        </Button>
      ))
    }
  </div>
);
}